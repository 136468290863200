export const optionData = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  // 图标右侧上方的下载按钮等
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      magicType: { show: true, type: ['line', 'bar'] },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  legend: {
    data: ['代码行数', '缺陷数']
  },
  xAxis: [
    {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed'],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '代码行数',
      min: 0,
      max: 300,
      interval: 50,
      axisLabel: {
        formatter: '{value} 行'
      }
    },
    {
      type: 'value',
      name: '缺陷数',
      min: 0,
      max: 25,
      interval: 5,
      axisLabel: {
        formatter: '{value} 个'
      }
    }
  ],
  series: [
    {
      name: '缺陷数',
      type: 'bar',
      itemStyle: {
        color: 'rgba(222, 110, 106, 0.9)' // 设置折线图颜色
      },
      tooltip: {
        valueFormatter: function (value) {
          return value + ' 行';
        }
      },
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
      ]
    },
    {
      name: '代码行数',
      type: 'line',
      itemStyle: {
        color: 'rgba(242, 220, 138, 0.9)' // 设置折线图颜色
      },
      yAxisIndex: 0,
      tooltip: {
        valueFormatter: function (value) {
          return value + ' 个';
        }
      },
      data: [20.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
    }
  ]
}
