import request from '@/utils/request.js'

// 查询指定指标度量数据
export const defectAnalysis = (data) => {
  return request({
    method: 'GET',
    url: 'defectAnalysis',
    params: {
      ...data
    }
  })
}
