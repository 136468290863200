// 缺陷严重率

export const severityData = {
  title: {
    text: '缺陷严重率',
    subtext: '严重的缺陷数量与发现的缺陷数量的比例',
    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)' // 设置悬浮提示框的格式，{d}% 表示展示百分比
  },
  grid: {
    top: '80%', // 距离容器顶部 50%
    bottom: '0%', // 距离容器底部 0%
    left: '50%', // 距离容器左侧 0%
    right: '0%' // 距离容器右侧 0%
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: '阶段缺陷严重率',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 300, name: 'P0' },
        { value: 735, name: 'P1' },
        { value: 580, name: 'P2' },
        { value: 484, name: 'P3' },
        { value: 300, name: 'P4' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      label: {
        formatter: '{b}: {d}%'
      }
    }
  ]
}
