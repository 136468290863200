// 缺陷到达率

export const arrivalData = {
  title: {
    text: '缺陷到达率',
    subtext: '每个阶段中发现的缺陷在全部缺陷的占比',
    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)' // 设置悬浮提示框的格式，{d}% 表示展示百分比
  },
  grid: {
    top: '80%', // 距离容器顶部 50%
    bottom: '0%', // 距离容器底部 0%
    left: '50%', // 距离容器左侧 0%
    right: '0%' // 距离容器右侧 0%
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: '阶段缺陷到达率',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 300, name: '阶段1' },
        { value: 735, name: '阶段2' },
        { value: 580, name: '阶段3' },
        { value: 484, name: '阶段4' },
        { value: 300, name: '阶段5' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      label: {
        formatter: '{b}: {d}%'
      }
    }
  ]
}
