<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>缺陷度量分析
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>缺陷密度</span>
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </div>
      <div ref="chart_density" class="defect-echarts-class"></div>
    </el-card>

    <div class="defect-metric-class">
      <el-card class="box-card box-card-defact">
        <div slot="header" class="clearfix">
          <span>缺陷到达率</span>
          <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div ref="chart_arrival" class="defect-echarts-class"></div>
      </el-card>
      <el-card class="box-card box-card-defact">
        <div slot="header" class="clearfix">
          <span>缺陷修复率</span>
          <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div ref="chart_repair" class="defect-echarts-class"></div>
      </el-card>
    </div>

    <div class="defect-metric-class">
      <el-card class="box-card box-card-defact">
        <div slot="header" class="clearfix">
          <span>缺陷有效率</span>
          <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div ref="chart_effective" class="defect-echarts-class"></div>
      </el-card>
      <el-card class="box-card box-card-defact">
        <div slot="header" class="clearfix">
          <span>缺陷严重率</span>
          <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div ref="chart_severity" class="defect-echarts-class"></div>
      </el-card>
    </div>

    <div class="defect-metric-class">
      <el-card class="box-card box-card-defact">
        <div slot="header" class="clearfix">
          <span>缺陷重开率</span>
          <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div ref="chart_reopen" class="defect-echarts-class"></div>
      </el-card>
      <el-card class="box-card box-card-defact">
        <div slot="header" class="clearfix">
          <span>缺陷逃逸率</span>
          <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div ref="chart_escape" class="defect-echarts-class"></div>
      </el-card>
    </div>

    <div class="defect-metric-class">
      <el-card class="box-card box-card-defact">
        <div slot="header" class="clearfix">
          <span>缺陷类型分布</span>
          <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div ref="chart_type" class="defect-echarts-class"></div>
      </el-card>
      <el-card class="box-card box-card-defact">
        <div slot="header" class="clearfix">
          <span>缺陷平均修复时长</span>
          <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div ref="chart" class="defect-echarts-class"></div>
      </el-card>
    </div>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>缺陷收敛趋势</span>
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </div>
      <div ref="chart" class="defect-echarts-class"></div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { optionData } from '@/views/chart_report/test_option_data.js'
import { densityData } from '@/views/chart_report/defectMetricAnalysis/defectDensity.js'
import { arrivalData } from '@/views/chart_report/defectMetricAnalysis/defectArrival.js'
import { repairData } from '@/views/chart_report/defectMetricAnalysis/defectRepair.js'
import { effectiveData } from '@/views/chart_report/defectMetricAnalysis/defectEffective.js'
import { severityData } from '@/views/chart_report/defectMetricAnalysis/defectSeverity.js'
import { reopenData } from '@/views/chart_report/defectMetricAnalysis/defectReopen.js'
import { escapeData } from '@/views/chart_report/defectMetricAnalysis/defectEscape.js'
import { typeData } from '@/views/chart_report/defectMetricAnalysis/defectType.js'
import { defectAnalysis } from '@/services/defectMetricAnalysis.js'
export default {
  name: 'DefectMetricAnalysls',
  data () {
    return {
      ccc: '缺陷度量分析'
    }
  },
  created () {},
  mounted () {
    this.echartsDensityReport()
    this.echartsArrivalReport()
    this.echartsRepairReport()
    this.echartsEffectiveReport()
    this.echartsSeverityReport()
    this.echartsReopenReport()
    this.echartsEscapeReport()
    this.echartsTypeReport()
    this.echartsReport()
  },
  methods: {
    async echartsDensityReport () {
      // 缺陷密度
      const chart = this.$refs.chart_density
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      const queryObj = {
        query_type: 'defect_metric',
        metric_type: 'density',
        iteration: true
      }
      const { data: res } = await defectAnalysis(queryObj)
      if (chart && res.data) {
        const xAxisData = []
        const seriesDefectNum = []
        const seriesCodeCount = []
        const seriesDefectDensity = []
        res.data.forEach(item => {
          xAxisData.push(item.iteration_name)
          seriesDefectNum.push(item.defect_count)
          seriesCodeCount.push(item.code_row_count)
          seriesDefectDensity.push(item.defect_density)
        });
        densityData.xAxis[0].data = xAxisData
        densityData.series[0].data = seriesDefectNum
        densityData.series[1].data = seriesCodeCount
        densityData.series[2].data = seriesDefectDensity

        // 计算缺陷数Y轴最大数和间隔数
        const maxDefectNumValue = Math.max(...seriesDefectNum) + Math.max(...seriesDefectNum) / 2
        const intervalDefectNumValue = maxDefectNumValue / 10

        densityData.yAxis[0].max = maxDefectNumValue
        densityData.yAxis[0].interval = intervalDefectNumValue

        // 计算代码行数最大数和间隔数
        const maxCodeCountValue = Math.max(...seriesCodeCount) + Math.max(...seriesCodeCount) / 2
        const intervalCodeCountValue = maxCodeCountValue / 10

        densityData.yAxis[1].max = maxCodeCountValue
        densityData.yAxis[1].interval = intervalCodeCountValue

        // 计算缺陷密度最大数和间隔数
        const maxDefectDensityValue = (Math.max(...seriesDefectDensity) + 0.1).toFixed(1)
        const intervalDefectDensityValue = maxDefectDensityValue / 10

        densityData.yAxis[2].max = maxDefectDensityValue
        densityData.yAxis[2].interval = intervalDefectDensityValue

        const myChart = echarts.init(chart, 'default')
        const option = densityData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    },
    async echartsArrivalReport () {
      // 缺陷到达率
      const chart = this.$refs.chart_arrival
      const queryObj = {
        query_type: 'defect_metric',
        metric_type: 'arrival',
        iteration: true
      }
      const { data: res } = await defectAnalysis(queryObj)
      if (chart && res.data) {
        console.log(res.data)
        const myChart = echarts.init(chart, 'default')
        const option = arrivalData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    },
    echartsRepairReport () {
      const chart = this.$refs.chart_repair
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        const myChart = echarts.init(chart, 'default')
        const option = repairData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    },
    echartsEffectiveReport () {
      const chart = this.$refs.chart_effective
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        const myChart = echarts.init(chart, 'default')
        const option = effectiveData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    },
    echartsSeverityReport () {
      const chart = this.$refs.chart_severity
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        const myChart = echarts.init(chart, 'default')
        const option = severityData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    },
    echartsReopenReport () {
      const chart = this.$refs.chart_reopen
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        const myChart = echarts.init(chart, 'default')
        const option = reopenData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    },
    echartsEscapeReport () {
      const chart = this.$refs.chart_escape
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        const myChart = echarts.init(chart, 'default')
        const option = escapeData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    },
    echartsTypeReport () {
      const chart = this.$refs.chart_type
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        const myChart = echarts.init(chart, 'default')
        const option = typeData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    },
    echartsReport () {
      const chart = this.$refs.chart
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        const myChart = echarts.init(chart, 'default')
        const option = optionData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .defect-echarts-class {
    width: auto;
    height: 400px;
  }
  .defect-metric-class {
    display: flex;
    justify-content: space-between;
  }
  .box-card-defact {
    width: 49%;
  }
</style>
