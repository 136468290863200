// 缺陷有效率

export const effectiveData = {
  title: {
    text: '缺陷有效率',
    subtext: '有效的缺陷数量与发现的缺陷数量的比例',
    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)' // 设置悬浮提示框的格式，{d}% 表示展示百分比
  },
  grid: {
    top: '80%', // 距离容器顶部 50%
    bottom: '0%', // 距离容器底部 0%
    left: '50%', // 距离容器左侧 0%
    right: '0%' // 距离容器右侧 0%
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: '阶段缺陷有效率',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 300, name: '有效缺陷' },
        { value: 735, name: '无效缺陷' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      label: {
        formatter: '{b}: {d}%'
      }
    }
  ]
}
