// 缺陷密度

const colors = ['#5470C6', '#91CC75', '#EE6666'];
export const densityData = {
  color: colors,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  grid: {
    right: '20%'
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  legend: {
    data: ['缺陷数', '代码行数', '缺陷密度']
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      // prettier-ignore
      data: ['迭代1', '迭代2', '迭代3', '迭代4', '迭代5', '迭代6', '迭代7', '迭代8', '迭代9', '迭代10', '迭代11', '迭代12']
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '缺陷数',
      position: 'right',
      alignTicks: true,
      min: 0,
      max: 100,
      interval: 10,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[0]
        }
      },
      axisLabel: {
        formatter: '{value} 个'
      }
    },
    {
      type: 'value',
      name: '代码行数',
      position: 'right',
      alignTicks: true,
      min: 0,
      max: 100,
      interval: 10,
      offset: 80,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[1]
        }
      },
      axisLabel: {
        formatter: '{value} 行'
      }
    },
    {
      type: 'value',
      name: '缺陷密度',
      position: 'left',
      alignTicks: true,
      min: 0,
      max: 100,
      interval: 10,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[2]
        }
      },
      axisLabel: {
        formatter: '{value}'
      }
    }
  ],
  series: [
    {
      name: '缺陷数',
      type: 'bar',
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
      ],
      tooltip: {
        valueFormatter: function (value) {
          return value + ' 个';
        }
      }
    },
    {
      name: '代码行数',
      type: 'bar',
      yAxisIndex: 1,
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
      ],
      tooltip: {
        valueFormatter: function (value) {
          return value + ' 行';
        }
      }
    },
    {
      name: '缺陷密度',
      type: 'line',
      yAxisIndex: 2,
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
      tooltip: {
        valueFormatter: function (value) {
          return value + '';
        }
      }
    }
  ]
}
